
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    import { AppProps } from "next/app";
import { useRouter } from "next/router";
import NextNprogress from "nextjs-progressbar";
import { Provider } from "react-redux";
import AppHelper from "@components/AppHelper";
import ToastContainer from "@components/common/ToastContainer";
import { initializeModules } from "@modules/modules-adapter";
import { AppState } from "@redux/reducer";
import { useStore } from '@redux/store';
// single point to import (S)CSS for native Next.js support, all other styles (e.g. from
// node-module) must be loaded there
import "../styles/index.scss";
/**
 *
 */
type PagePropsType = {
    /**
     * InitialReduxState seems necessary on persisting the store while the user navigates from page to page.
     * It is typed here to prevent typescript errors.
     *
     * @see https://github.com/vercel/next.js/discussions/49574
     */
    initialReduxState: AppState;
};
/**
 * This provides the central entrypoint of the application
 * - providing the redux-store for application-data
 * - activate the AppHelper to observe the user is using the app and authenticated
 * - connect the react-toast to notifications from the redux-store (to provide translated messages to the user)
 * - embed the central app-component with the given page-properties
 */
const FCPApp: React.FC<AppProps> = ({ Component, pageProps }: AppProps<PagePropsType>) => {
    initializeModules();
    // creates the redux-store for storing data
    const store = useStore(pageProps?.initialReduxState);
    /**
     * To force re-rendering of the full page when language changes, this main app page
     * listens to a changed locale. The re-rendering is triggered when the key of the
     * sub-components is changed: see below.
     * The whole page has to be rerendered to make sure, even translations are
     * updated that are provided by the pages, e.g. the page title that is provided by
     * the page into the BaseLayout.
     */
    const language = useRouter().locale;
    // NextNprogress gives a visual indication of route changes while pages are loading
    // ToastContainer wraps react-toastify to show small alert popups
    return <Provider store={store}>
    <AppHelper />
    <NextNprogress />
    <ToastContainer key={"toast-container-" + language}/>
    <Component {...pageProps} key={"page-" + language}/>
  </Provider>;
};
const __Next_Translate__Page__192be85fe34__ = FCPApp;

    export default __appWithI18n(__Next_Translate__Page__192be85fe34__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: true,
      loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
    });
  