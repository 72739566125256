/*
 * this file is for definition of constants for customers
 * e.g. for defining project phases/timelines for switching functions on/off
 *
 * NOTE: circular imports may lead to "ReferenceError: window is not defined"
 * NOTE imports of enums lead to ReferenceError: Cannot access <enum> before initialization: trying to read <enum>.<entry>
 * That's why most customer configurations are not typed with complex types but as string values or similar
 * @todo https://futureprojects.atlassian.net/browse/FCP-1651
 */
import { StringBasedStringArray } from "@basics/common"
import { platformIsInTestEnvironment } from "@basics/platform"
import { Routes } from "@basics/routes"
import { TimelinePhase, getLatestTimelinePhase } from "@basics/util-importless"
import { BoundsLiteral } from "@components/common/map/mapHelper"
import { SINN_PROTOTYPE_CLIENT_CONFIG_USED } from "config"

// this variable only exists to change easily the baseUrl of Sinn from a base url for development to production
export const SINN_BASE_URL = "https://staging.sinn-sachsen.de"

/** customer timeline */
const customerTimeline: TimelinePhase[] = [
  // { startDate: "2023-08-30", phase: 2 },
]

const currentPhase = getLatestTimelinePhase(customerTimeline)

// NOTE: put in the real env-var name!
export const CUSTOMER_PHASE: number = currentPhase?.phase
  ? currentPhase.phase
  : parseInt(process.env.CUSTOMER_PHASE, 10)


// #region partnermarket

/**
 * Which style should the SupportRequestCard have?
 * By default: SUPPORT_REQUEST_CARD_PROJEKTFABRIK_STYLE
 * Every other value leads to a "simple version"
 */
// as string and not as enum or other imported variable,
// b/c: ReferenceError: Cannot access 'SUPPORT_REQUEST_CARD_PROJEKTFABRIK_STYLE' before initialization
// or
// ReferenceError: Cannot access <enum> before initialization: trying to read <enum>.<entry>
// @todo: https://futureprojects.atlassian.net/browse/FCP-1329
// eslint-disable-next-line @typescript-eslint/no-inferrable-types
export const SUPPORT_REQUEST_CARD_STYLE: string = "SUPPORT_REQUEST_CARD_PROJEKTFABRIK_STYLE"

/**
 * order of the SupporterRole Filter in the Partnermarket
 * and when creating a SupportRequest
 */
export const SUPPORTER_ROLE_ORDER_AS_FILTER: string[] = [
  'sponsor',
  'supplier',
  'feedback_provider',
  'expert',
  'ambassador',
  'contributor',
]

/**
 * available feedback post types for the customer when creating a discussion/post
 * in the wanted order, based on FeedbackPostType
 *
 * set to null if all default types shall be used
 */
export const CUSTOMER_FEEDBACK_POST_TYPES: string[] = null
// [
//   'hint',
//   'criticism',
//   // 'troll', // no trolling for cottbus
//   'question',
//   'support'
// ]

/**
 * should the networking dashboard be used?
 *
 * NOTE: there must be at least an link/access to the SupportRequests, if the partnermarket is available
 * and USE_NETWORKING_DASHBOARD === false
 */
export const USE_NETWORKING_DASHBOARD = true


// #endregion partnermarket


/**
 * Alternative routes to be processed by the relevant pages to forward the user instead of
 * showing their content.
 *
 * NOTE: currently only implemented in pages Imprint + DataProtection
 *
 * @todo: https://futureprojects.atlassian.net/browse/FCP-1438
 */
export const ALTERNATIVE_ROUTES: StringBasedStringArray = {}

if (SINN_PROTOTYPE_CLIENT_CONFIG_USED) {
  ALTERNATIVE_ROUTES[Routes.Imprint] = `${SINN_BASE_URL}/impressum`
  ALTERNATIVE_ROUTES[Routes.DataProtection] = `${SINN_BASE_URL}/datenschutz`
  ALTERNATIVE_ROUTES[Routes.TermsOfUse] = `${SINN_BASE_URL}/nutzungsbedingungen`
}

// #region social media links
// store URLs in those variables to configure the social media icons
/**
 * URL to a Facebook page of the current customer
 */
export const SOCIAL_MEDIA_FACEBOOK: string = null
/**
 * URL to a Instagram page of the current customer
 */
export const SOCIAL_MEDIA_INSTAGRAM: string = null
// #endregion social media links


/**
 * enables/disables the option that a processmanager is able to edit a project's profile
 */
export const ENABLE_MANAGER_EDIT_PROJECT_PROFILE = false

// #region map

/**
 * Map: which drawing options should be provided to the project team?
 * options: marker, polyline, polygon
 */
export const CUSTOMER_PROJECT_MAP_DRAW_FUNCTIONS: string[] = ["marker", "polygon", /* polyline */]

/**
 * what area of the map is shown by default?
 * Dresden: [51.0504, 13.7373]
 * Saxony: [[50.5, 12.5],  [51.5, 14.5]]
 */
// The usage of this imported type may fail, when the file, where the type is located
// imports this config-file: it results in a circular connection!
export const CUSTOMER_MAP_BOUNDS: BoundsLiteral = [
  [50.5, 12.5],
  [51.5, 14.5]
]

// #endregion map


// #region OIDC

/**
 * Configuration of "native" login (i.e. accounts created & managed solely through the backend API)
 * on the Projektfabrik platform.
 */
export enum PlatformLoginMode {

  /** Native login form is shown on the login page. */
  Open = "open",

  /** Native login is available on the login page, but the form is only shown in a modal. */
  Discouraged = "discouraged",

  /** Native login is not supported at all. That means all accounts are managed via OIDC provider(s). */
  Disabled = "disabled"
}

/**
 *
 */
// Kommentar von Norbert:
// @todo OAuth: damit die Tests durchlaufen habe ich erstmal gesagt: wenn die Plattform im Testmodus ist, soll
// der proprietäre Login funktionieren und OAuth ignoriert werden. Da ich kein Bild davon habe, wie mit dieser Datei
// weiter verfahren werden soll und wie dann die Testung des proprietären und des OICD-Logins parallel laufen kann
// habe ich das erstmal so gemacht
export const PLATFORM_LOGIN_MODE: PlatformLoginMode = platformIsInTestEnvironment() ? PlatformLoginMode.Open : PlatformLoginMode.Discouraged

/**
 * Should the oauth-verification page show the local instance's logo/text left to the provider's logo?
 * Should be disabled as long as we do not have an own logo, or there is no public logo link.
 * @todo oauth maybe replace with a logo link (string).
 */
export const SHOW_PLATFORM_LOGO_IN_OAUTH_VERIFICATION_PAGE = false

/**
 * If there is only one OIDC provider:
 * Should the login page immediately forward the user to the OIDC provider's auth page?
 * NOTE if yes, experienced/managing users may circumvent this by passing `CIRCUMVENT_IMMEDIATE_FORWARDING_PARAM_NAME` url parameter
 * with the value of CIRCUMVENT_IMMEDIATE_FORWARDING_PARAM_NAME (e.g. `cirumventImmediateForwarding=cirumventImmediateForwarding`).
 * Default: false.
 * @todo oauth refactor to program-specific config
 */
export const IMMEDIATE_FORWARD_TO_PROVIDER_IF_SINGLE = true

/**
 * URL param name that enables alternatives when IMMEDIATE_FORWARD_TO_PROVIDER_IF_SINGLE is set.
 * Used with value `PROPRIETARY_LOGIN_MODE`.
 */
export const LOGIN_MODE_PARAM_NAME = "login"

/**
 * URL param value that enables alternatives when IMMEDIATE_FORWARD_TO_PROVIDER_IF_SINGLE is set.
 * Used with param name `LOGIN_MODE_PARAM_NAME`.
 */
export const PROPRIETARY_LOGIN_MODE = "projektfabrik"

// #endregion OIDC